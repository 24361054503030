import * as React from 'react';
import { Link } from 'gatsby';
import { Icon } from 'antd';
import { ContentfulSection } from '../../types/graphql-types'; // eslint-disable-line import/no-unresolved
import './contentFooter.less';

type Props = {
  previousSection?: ContentfulSection;
  nextSection?: ContentfulSection;
};

const ContentFooter: React.FC<Props> = ({ previousSection,  nextSection }: Props) => {
  return (
    <div className="section">
      {previousSection && (
        <Link to={`${previousSection.slug}`} className="link">
          <Icon type="left-circle" /> {`${previousSection.title}`}
        </Link>
      )}
      {nextSection && (
        <Link to={`${nextSection.slug}`} className="link">
          {`${nextSection.title}`}<Icon type="right-circle" />
        </Link>
      )}
    </div>
  );
};

export default ContentFooter;
