/* eslint-disable prettier/prettier */
import { graphql, PageRendererProps } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import ContentfulRichText from '../components/contentfulRichText';
import { ContentfulSection, ContentfulSectionConnection, SortOrderEnum } from '../../types/graphql-types'
import ContentFooter from '../components/contentFooter' // eslint-disable-line import/no-unresolved

interface Props extends PageRendererProps {
  pageContext: {
    slug?: string;
  };
  data: {
    contentfulSection: ContentfulSection;
    allContentfulSection: ContentfulSectionConnection;
  };
}

const SectionTemplate: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const { pageContext: { slug } } = props;
  const node = data.contentfulSection;
  const { allContentfulSection } = data;
  const sections = allContentfulSection.edges.map(edge => edge.node);
  const activeSection = sections.find(section => section.slug === slug);
  let previousSection;
  let nextSection;
  if (activeSection != null) {
    const activeSectionIndex = sections.indexOf(activeSection)
    if (activeSectionIndex > 0) {
      previousSection = sections[activeSectionIndex - 1];
    }
    if (activeSectionIndex < sections.length - 1) {
      nextSection = sections[activeSectionIndex + 1];
    }
  }
  return (
    <Layout sections={sections} activeSection={activeSection} previousSection={previousSection} nextSection={nextSection}>
      <h2>{node.title}</h2>
      <ContentfulRichText
        document={node.content?.json}
        key={`${node.id}-content`}
      />
      <ContentFooter nextSection={nextSection} previousSection={previousSection} />
    </Layout>
  );
};

export const query = graphql`
  query SectionBySlug($slug: String!) {
      contentfulSection(slug: { eq: $slug }) {
          id
          title
          content {
              json
          }
      }
      allContentfulSection(sort: {fields: [sortOrder], order: ASC}) {
          edges {
              node {
                  id
                  title
                  slug
                  sortOrder
              }
          }
      }
  }
`

export default SectionTemplate;