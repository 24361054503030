import * as React from 'react';
import {
  BLOCKS,
  INLINES,
  // MARKS, Document, Block
} from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';

type Props = {
  document: {
    nodeType: BLOCKS.DOCUMENT;
    content: any[];
    data: any;
  };
};

function getObjectKeyArray(obj: any): string[] {
  if (typeof obj !== 'object') return [];
  const arr: string[] = [];
  Object.keys(obj).forEach(key => {
    arr.push(key);
  });
  return arr;
}

const ContentfulRichText: React.FC<Props> = ({ document }: Props) => {
  const options: Options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        if (!node.data.target.fields) return;
        const { file, description } = node.data.target.fields;
        const locales = getObjectKeyArray(file);
        return locales.map(locale => (
          <img
            src={file && file[locale].url}
            alt={description && description[locale]}
            data-locale={locale}
            key={locale}
          />
        ));
      },
      [INLINES.ENTRY_HYPERLINK]: node => {
        if (!node.data.target) return;
        if (!node.content || node.content.length < 1) return;

        const display = node.content[0].data.value;
        const path = node.data.target.fields.slug['en-US'];
        return <a href={`/${path}`}>{display}</a>;
      },
    },
  };
  return <div>{documentToReactComponents(document, options)}</div>;
};
export default ContentfulRichText;
